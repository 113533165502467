export default {
  'Items por página' : 'Items per page',
  de: 'of',
  'y': 'and',
  English : 'English',
  Portuguese : 'Portuguese',
  Spanish: 'Spanish',
  Salir: 'Logout',
  Usuarios: 'Users',
  Usuario: 'User',
  Paises: 'Countries',
  'Brasil' : 'Brazil',
  'República Dominicana' : 'Dominican Republic',
  'Granada' : 'Grenada',
  'Guayana Francesa' : 'French Guiana',
  'Guyana' : 'Guiana',
  País: 'Country',
  Regiones: 'Regions',
  Region: 'Region',
  Escuelas: 'Schools',
  Escuela: 'School',
  Perfil: 'Profile',
  Evaluación: 'Evaluation',
  "Estadísticas": 'Statistics',
  "Por evaluación": 'By evaluation',
  "Por escuela": 'By school',
  "Por continente": 'By continent',
  "Por país": 'By country',
  "Por región": 'By region',
  "Latinoamerica y el Caribe": 'Latin America and the Caribbean',
  'Paises asignados' : 'Assigned Countries',
  'Regiones asignadas' : 'Assigned Regions',
  'Escuelas asignadas' : 'Assigned Schools',
  'PQI Evaluation': 'PQI Evaluations',
  'All rights Reserved.' : 'All rights Reserved.',
  'Powered by' : 'Powered by',
  Home : 'Home',
  'Ir al contenido' : 'Skip to content',
  FAQs : 'FAQs',
  Tutorial : 'Tutorial',
  Evaluaciones: 'Evaluations',
  Resultados: 'Scores',
  Período: 'Period',
  Título: 'Title',
  Nota: 'Note',
  Instrumentos: 'Instruments',
  Instrumento: 'Instrument',
  'Crear Usuario': 'Create User',
  'Editar Usuario': 'Edit User',
  'Editar información de Usuario': 'Edit user data',
  'Crear Region': 'Create Region',
  'Editar Region': 'Edit Region',
  'Crear Escuela': 'Create School',
  'Crear Evaluación': 'Create Evaluation',
  'Eliminar evaluación' : 'Delete evaluation',
  'Es super administrador': 'Is super admin',
  'No tiene permisos para crear un usuario super administrador.': 'You do not have permissions to create a super administrator user.',
  Nombre: 'Name',
  Files: 'Files',
  Add: 'Add',
  Close : 'Close',
  'Gráfico': 'Graph',
  Descargar: 'Download',
  Acciones: 'Actions',
  Editar: 'Edit',
  Crear: 'Create',
  Opciones: 'Options',
  'Opción': 'Option',
  Modalidad: 'Modality',
  Nivel: 'Level',
  Puntaje: 'Score',
  'Ayuda': 'Help',
  'Imprimir gráficos' : 'Print charts',
  'General': 'General',
  'Por categoria': 'By category',
  'Por indicador': 'By indicator',
  'Plan de acción' : 'Action plan',
  'Tipo de gestión': 'Type of management',
  'Resultados generales': 'General results',
  'Puntaje de la categoría': 'Category score',
  'Puntaje del indicador' : 'Indicator score',
  'Distribución de los criterios de valoración': 'Distribution of assessment criteria',
  Rol: 'Role',
  'Teléfono': 'Phone',
  'Buscar por nombre de usuario': 'Search by username',
  'Buscar por nombre de region': 'Search by region name',
  'Buscar por nombre de país': 'Search by country name',
  'Buscar por nombre de escuela': 'Search by school name',
  'Buscar por nombre de evaluación': 'Search by evaluation name',
  'Nombre completo': 'Full name',
  'Nombre de usuario': 'Username',
  Contraseña: 'Password',
  'Contraseña anterior' : 'Last password',
  'Contraseña nueva' : 'New password',
  'Repetir la nueva contraseña' : 'Repeat password',
  'Confirmar contraseña': 'Confirm password',
  '¿Desea recibir emails automaticamente?': 'Do you wish to receive emails automatically?',
  'No, gracias': 'No, thanks',
  Suscribirse: 'Subscribe',
  Cancelar: 'Cancel',
  Siguiente: 'Next',
  Date: 'Date',
  Semana: 'Week',
  Detalles: 'Details',
  Agregar: 'Add',
  Ver: 'View',
  Upload: 'Upload',
  Guardar: 'Save',
  Cerrar: 'Close',
  'Close modal' : 'Close modal',
  Asignar: 'Assign',
  'Progreso': 'Progress',
  'Asignar todas': 'Asign all',
  'Asignar selección': 'Asign selection',
  Filtrar: 'Filter',
  Finalizar: 'Finish',
  'No hay paises asignados para este usuario.' : 'There are no countries assigned to this user.',
  'No hay regiones asignadas para este usuario.' : 'There are no regions assigned to this user.',
  'No hay escuelas asignadas para este usuario.': 'There are no schools assigned to this user.',
  'Sin información disponible' : 'No data available',
  'Cambiar Contraseña': 'Change Password',
  'Cambiar contraseña de usuario' : 'Change user password',
  'Selecciona...': 'Choose...',
  'Seleccione un archivo' : 'Choose file',
  'Campo requerido': 'Required field',
  'Email inválido': 'Invalid email',
  'Nombre de la Region': 'Region name',
  Administrador: 'Administrator',
  'Representante de país': 'Country representative',
  'Coordinador regional': 'Regional coordinator',
  'Representante escolar': 'School representative',
  'Nombre de la Escuela': 'School name',
  'Nombre del proyecto': 'Project name',
  'Profesiones': 'Professions',
  'Cantidad de estudiantes': 'Number of students',
  'Estudiantes con discapacidades': 'Students with disabilities',
  'Estudiantes con multiple discapacidades': 'Students with multiple disabilities',
  'Estudiantes con discapacidades visuales': 'Visually impaired students',
  'Estudiantes con sordoceguera': 'Students with deafblindness',
  'Cantidad de profesores': 'Number of teachers',
  'Cantidad de otros profesionales': 'Number of other professionals',
  'Personal total': 'Total staff',
  'Educación regular': 'Regular education',
  'Educación especial': 'Special education',
  'Educación domiciliaria hospitalaria': 'Hospital home education',
  'Centro de primera infancia': 'Early childhood center',
  'Público': 'Public',
  'Privado': 'Private',
  'Preescolar': 'Pre-school',
  'Escolar primario': 'School',
  'Escolar secundario': 'High school',
  'Instrumento Escolar': 'School Instrument',
  'Instrumento Preescolar': 'Pre-School Instrument',
  '¿Estás seguro de eliminar el usuario': 'Are you sure you want to delete the user',
  '¿Estás seguro de eliminar la region': 'Are you sure you want to delete the region',
  '¿Estás seguro de eliminar la escuela': 'Are you sure you want to delete the school',
  '¿Estás seguro de eliminar esta evaluación' : 'Are you sure to delete this evaluation',
  'Eliminar': 'Delete',
  'No hay datos disponibles.': 'No data available.',
  Indicadores: 'Indicators',
  'Prácticas-autonomía': 'Autonomy-Practical activities',
  'Trabajo colaborativo': 'Collaborative work',
  'Comunicación transversal': 'Cross-functional communication',
  'Inclusión educativa': 'Educational inclusion',
  'Debe cargar algún indicador' : 'Must register some indicator',
  'para poder visualizar gráficos' : 'to be able to visualize graphs',
  Comentarios: 'Comments',
  'Comentario completo': 'Full comment',
  'Conocimiento sobre el estudiante': 'Knowledge about the student',
  'Planificación actividades y contenidos': 'Plans, activities and contents',
  'Organización del entorno educativo': 'Organization of the educational environment',
  'Gestión escolar': 'Program management',
  'Sobre los profesionales': 'About the staff',
  'Sobre la participación de los estudiantes': 'About student’s participation',
  'Sobre la familia': 'About the family',
  'Información del niño/niña' : "Child's Information",
  'Programa en educación temprana: planificación y actividades' : 'Early Childhood Education Program: Planning and Activities',
  'Organización del entorno' : 'Environment Organization',
  'Gestión del servicio' : 'Service Management',
  'Prácticas profesionales' : 'Professional Practices',
  'Interacciones de calidad entre niño/niña y adulto' : 'Quality Interactions between Child and Adult',
  'Participación de la familia' : 'Family Involvement',
  //schoolIndicatorParser:
  'El equipo de profesionales trabaja de forma apropiada con el/la estudiante, en un proceso de valoración colaborativa y coordinada, demostrando estar formado acerca del impacto de la discapacidad múltiple y/o sordoceguera e interactuando comunicativamente de forma apropiada.': 'Professional staff members work with students is appropriate. The assessment process is developed in a collaborative and well-coordinated way, where the staff demonstrates an understanding of the impact of VIMD/DB and communicates properly.',
  'Las evaluaciones sensoriales funcionales (visión y audición) se completan en cada niño.': 'Functional sensory (vision and hearing) assessments are completed on every child.',
  'El personal del programa coordina o facilita evaluaciones de visión y audición con especialistas cualificados en atención ocular o auditiva (oftalmólogo, optometrista, otolaringólogo, etc.).': 'The program staff coordinates or facilitates vision and hearing assessments by qualified specialists (ophthalmologist, optometrist, audiologist, etc.)',
  'Las evaluaciones se realizan durante rutinas naturales permitiendo observar las interacciones espontáneas del niño.': 'Assessments are conducted during children’s normal routine so that their spontaneous interactions are observed.',
  'Los resultados de la evaluación son revisados periódicamente y se utilizan de forma actualizada en la planificación y en el seguimiento.': 'Assessment results are reviewed periodically and are used to plan, monitor, and modify the child’s program.',
  'Las planificaciones diarias, las actividades y las estrategias de enseñanza están claramente relacionadas con las metas, los objetivos y los intereses de los estudiantes incluidos en el PEI.': 'Lesson plans, activities and teaching strategies are clearly related to the goals, objectives and interests of the student included in the IEP.',
  'Las actividades del aula incluyen áreas del currículo expandido y están alineadas con el currículo nacional.': 'Classroom activities include topics from the expanded curriculum and are aligned with the National Curriculum.',
  'Las actividades y contenidos se proponen a través de situaciones problemáticas que motivan al estudiante a participar, compartir y hacer elecciones.': 'Activities and content are implemented through problem-solving situations that motivate the student to participate, share and make choices.',
  'Hay tiempo suficiente para las transiciones entre actividades que respeten las habilidades individuales y permitan la comprensión y participación tranquila de los estudiantes.': 'There is sufficient time for transitions between activities that respect the students’ individual skills, allowing them to understand and calmly participate.',
  'TIEMPO: El día se organiza de manera fluida, conectando las actividades entre sí (por ejemplo, escribir una lista de artículos que se deben comprar para una actividad de cocina, ir a la tienda a comprarlos, completar la actividad de cocina, escribir sobre ella en un diario).': 'TIME: The day is fluently organized and activities are connected to each other (for example: writing a shopping list, buying the items on the list, completing a cooking activity, and journaling about it).',
  'Hay un horario claramente definido, consistente y accesible para los estudiantes.': 'There is a clearly-defined and consistent schedule that is accessible to the students.',
  'CONTEXTO: El espacio y las áreas de trabajo, son seguros y adaptados a las necesidades de apoyo derivadas de la discapacidad (por ej: para la discapacidad visuales: iluminación, contraste, espacios definidos, etc.; para la pérdida de audición, revestimiento del suelo y de las ventanas para reducir el ruido).': 'CONTEXT: The space and work areas are safe and are adapted to accommodate the needs of students with disabilities (illumination, contrast and defined spaces for children with visual impairment; soundproof windows and special floors for children with hearing loss).',
  'Se controla el umbral sensorial en el entorno para fomentar la atención y el máximo acceso al aprendizaje.': 'Sensory threshold in the environment is controlled to encourage students to pay attention, giving them maximum access to learning.',
  'Se utilizan múltiples ubicaciones en la escuela y dentro de la comunidad para fomentar la enseñanza en entornos naturales.': 'Multiple locations in the school and within the community are used to encourage teaching in natural environments.',
  'Los materiales didácticos son apropiados para las edades y los niveles de desarrollo de los estudiantes y son accesibles para su uso en el aula.': 'Teaching materials are appropriate for the students’ ages and levels of development. They are also accessible for their use in the classroom.',
  'Los miembros del personal demuestran saber cómo ayudar a los estudiantes anticipando y hablando sobre las actividades. Por ejemplo, usando calendarios, proporcionando un nivel apropiado de asistencia y cantidad adecuada de pistas para completar una tarea.': 'Staff members show they know how to help the students by anticipating and talking about the activities. For example, they use calendars, assist accordingly, and provide clues to complete a task.',
  'Hay suficiente personal docente representando a los diferentes niveles de gestión para atender las necesidades de aprendizaje de cada niño.': 'There are enough teachers to support every child’s needs at each stage of the learning process.',
  'Hay servicios de apoyo organizados para atender las necesidades educativas del alumno (fisioterapia, orientación y movilidad, etc.).': 'School has organized support services to meet students’ educational needs (physical therapy, orientation and mobility, etc).',
  'El personal recibe supervisión y apoyo de forma organizada por alguien con conocimientos y habilidades en la educación de niños con DM/DV/SC.': 'The personnel is supervised and receives organized support from someone with knowledge and skills on education of children with MD/VI/DB.',
  'Hay oportunidades planificadas para que todo el personal, incluidos los padres, equipo de paraprofesionales y administradores participen en actividades de formación e intercambio de información sobre cómo se trabaja y da respuesta a las necesidades de estudiantes con SC/DM/DS.': 'There are planned opportunities for the entire educational community to participate in training and exchange activities on how to work and respond to the needs of children with MD/VI/DB.',
  'Hay un sistema eficaz de comunicación entre el hogar y la escuela (visitas al hogar, libros de comunicación, etc.) para que el personal y las familias puedan mantenerse bien informados sobre el alumno.': 'An efficient communication system between the students’ home and the school is in place so that both families and teachers are well informed about their progress (i.e. home visits, communication books, etc.).',
  'Los administradores del programa interactúan con los funcionarios a nivel local, regional y nacional que son responsables de la supervisión del programa.': 'Program’s administrators interact with local, regional, and national officers responsible to supervise the program.',
  'Los Planes Educativos Individualizados (PEI) están documentados y tienen un formato específico para su registro.': 'Individualized Educational Plans (IEPs) are documented and have a specific registration format.',
  'FORMACIÓN EN COMUNICACIÓN: El personal entiende, reconoce y responde a los modos de comunicación más naturales de sus estudiantes, mostrando una relación cómoda y mutuamente agradable.': 'TRAINING IN COMMUNICATION: The staff understands, recognizes, and responds to students’ natural forms of communication. The staff and the students communicate with ease and comfort.',
  'El personal es capaz de modelar el siguiente nivel superior de comunicación fomentando el desarrollo de la comunicación de sus estudiantes.': 'The staff is able to model the next higher level of communication, thus encouraging the students’ communication development.',
  'El personal entiende la intención comunicativa de los comportamientos desafiantes en los estudiantes y responde de forma adecuada.': 'Staff understands the communicative intention of challenging behaviors and responds appropriately.',
  'TRABAJO EN EQUIPO: Los profesores y administradores se comunican claramente entre sí.': 'TEAMWORK :Teachers and administrators communicate clearly with each other.',
  'El personal trabaja cooperativamente desde sus roles disciplinarios y comparten responsabilidades para el desarrollo de planes educativos.': 'The staff collaborates in their disciplinary roles and share responsibilities in the development of education plans.',
  'Los estudiantes tienen acceso a los apoyos comunicativos durante todo el día.': 'The students have access to communication support throughout the day.',
  'Cada estudiante participa plenamente en todas las actividades, independientemente de su nivel de desarrollo.': 'Every student fully participates in all activities, regardless of their level of development.',
  'El estudiante se manifiesta motivado, atento e involucrado en la actividad que se le propone.': 'The student looks motivated, attentive, and involved in the proposed activities.',
  'El estudiante reconoce y es reconocido por el resto de sus compañeros.': 'The student recognizes his peers and he is recognized by them.',
  'Se comparte un sistema comunicativo en común entre los compañeros promoviendo sostener interacciones y lazos de amistad.': 'A common communication system is shared by the members of the class to foster interaction and friendship.',
  'El estudiante usa los diferentes niveles de apoyo disponibles para participar.': 'The student uses the different levels of support available to participate.',
  'Los estudiantes colaboran entre sí.': 'The students help each other.',
  'Se fomenta que los miembros de la familia compartan información y expresen sus expectativas durante el proceso de evaluación, generando una comunicación fluida y cercana, y permitiendo una toma de decisiones equitativa y cooperativa.': 'It is encouraged that family members share information and express their expectations during the assessment process, where communication is fluid and close, allowing a decision-making process that is equitable and collaborative.',
  'Hay actividades coordinadas que promueven la creación de redes entre las familias.': 'There are coordinated activities that promote networking among families.',
  'Las familias participan activamente en las actividades del programa.': 'Families participate actively in the program’s activities.',
  'El personal del programa se reúne con las familias en sus hogares y/o en la escuela de forma regular.': 'Program staff meet families in their homes and/or at school on a regular basis.',
  'Se ofrecen talleres de formación para las familias que tienen hijos en el programa, incluyendo apoyo e instrucción en estrategias de comunicación eficaces para su hijo.': 'Workshops are offered for parents with children in the program. These workshops include support and training on communication strategies that are effective for their children.',

  //preschoolIndicatorParser:
  'El equipo de profesionales trabaja de forma apropiada con cada niño/a, en un proceso de valoración colaborativa y coordinada, demostrando estar formado acerca del impacto de la discapacidad múltiple y/o sordoceguera e interactuando comunicativamente de forma apropiada.' : 'The team of professionals works with each child appropriately, in a process of collaborative and coordinated assessment, which proves they are trained in the impact of the multiple disability and/or deaf-blindness and that they are interacting in an appropriate communicative manner.',
  'Las evaluaciones funcionales (sensoriales y motrices) se completan en cada niño/a.' : 'The functional assessment (sensory and motor) are completed for each child.',
  'El personal del programa de educación temprana, coordina o facilita un trabajo en red sobre evaluaciones con especialistas cualificados en atención ocular o auditiva (oftalmólogo, optometrista, otolaringólogo, etc.).' : 'The personnel of the early education program, coordinates or facilitates a network collaboration with assessments from specialists qualified in eye care and auditory attention (ophthalmologist, optometrist, otolaryngologist, etc.).',
  'Los resultados de la evaluación son revisados periódicamente (por ejemplo cada 3/6 meses) y se utilizan de forma actualizada en la planificación y en el seguimiento.' : 'Assessment results are reviewed periodically (e.g., every 3/6 months) and are used to plan, monitor, and modify the child’s program.',
  'La organización del programa incentiva el juego (variados y adecuados a la edad) y las interacciones sociales para alcanzar logros en las diferentesáreas de desarrollo.' : 'The organization of the program encourages play (varied and age-appropriate) and social interactions to achieve milestones in different areas of development.',
  'Se ofrecen rutinas diarias a través de las cuales el niño/niña puedan anticipar distintas actividades en diferentes espacios' : 'Daily routines are offered through which the child can anticipate different activities in different spaces.',
  'Se integran las rutinas básicas de alimentación, cuidado e higiene para el desarrollo de habilidades sociales.' : 'Basic feeding, care, and hygiene routines are integrated to promote the development of social skills.',
  'Las actividades proponen el aprendizaje a traves de los diferentes sentidos.' : 'The activities propose learning through different senses.',
  'Los miembros del personal demuestran saber cómo ayudar a los estudiantes proporcionando apropiado nivel de asistencia y promoviendo la independencia y la seguridad para la participación de los niños/niñas.' : "The staff members demonstrate knowledge of how to assist students by providing appropriate levels of support and promoting independence and safety for children's participation.",
  'TIEMPO: Hay una rutina consistente en la organización de la jornada diaria.' : 'TIME: There is a consistent routine in organizing the daily schedule.',
  'El día se organiza de manera fluida conectando las actividades.' : 'The day is organized in a fluid manner, connecting the activities.',
  'CONTEXTO: El espacio y las áreas de trabajo, son seguros y adaptados a las necesidades de apoyo derivadas de la discapacidad (por ej:para la discapacidad visuales: iluminación, contraste, espacios definidos, etc.; para la pérdida de audición, revestimiento del suelo y de las ventanas para reducir el ruido; para las discapacidades físicas accesibilidad en el entorno)' : 'CONTEXT: The space and work areas are safe and adapted to the support needs arising from disabilities (e.g., for visual disabilities: lighting, contrast, defined spaces, etc.; for hearing loss: flooring and window coverings to reduce noise; for physical disabilities: environmental accessibility).',
  'Se utilizan múltiples ubicaciones en el servicio y dentro de la comunidad para fomentar la enseñanza en entornos naturales y apropiados.' : 'Multiple locations within the service and within the community are used to promote teaching in natural and appropriate environments.',
  'Los materiales utilizados en las actividades (rotulados, con contraste, texturas agradables, etc) proponen, a partir de su disposición y caracteríticas, la exploración activa y la acción del niño sobre el objeto.' : "The materials used in the activities (labeled, with contrast, pleasant textures, etc.) propose, through their arrangement and characteristics, active exploration and the child's interaction with the object.",
  'Hay suficiente personal, representando a los diferentes niveles de gestión, para atender las necesidades de aprendizaje de cada niño.' : 'There is sufficient staff, representing different levels of management, to meet the learning needs of each child.',
  'Hay oportunidades planificadas para que toda la comunidad educativa participe en actividades formación e intercambio sobre como trabajar y dar respuesta a las necesidades de los niños/niñas con DM/DV/SC' : 'There are planned opportunities for the entire educational community to participate in training and exchange activities on how to work and respond to the needs of children with MD/VI/DB.',
  'Hay un sistema eficaz de comunicación entre el hogar y el servicio (visitas al hogar, libros de comunicación…) para que el personal y las familias puedan mantenerse bien informados sobre el niño/niña' : 'There is an effective communication system between the home and the service (home visits, communication books, etc.) to ensure that the staff and families can stay well-informed about the child.',
  'Los administradores del servicio trabaja en red con organizaciones, ministerios u otros programas para organizar la transición de los niños/niñas a otros servicios.' : 'The service administrators work in collaboration with organizations, ministries, or other programs to organize the transition of children to other services.',
  'Existen planes individualizados documentados con un formato específico en el que participen colaborativamente entre todos los miembros del equipo' : 'There are individualized plans documented in a specific format in which all team members collaboratively participate.',
  'El personal brinda oportunidades para que el niño o niña pueda realizar elecciones a lo largo de la jornada.' : 'The staff provides opportunities for the child to make choices throughout the day.',
  'El personal brinda oportunidades para que el niño o niña inicie la comunicación como por ejemplo la toma de turno, pedir más.' : 'The staff provides opportunities for the child to initiate communication, such as taking turns and requesting more.',
  'TRABAJO EN EQUIPO: Los profesores y administradores se comunican claramente entre sí. Por ejemplo comparten información relacionada de los niños/niñas para trabajar de forma consistente y coordinada' : 'TEAMWORK: Teachers and administrators communicate clearly with each other. For example, they share relevant information about the children to work consistently and coordinately.',
  'El personal trabaja cooperativamente desde sus roles disciplinarios y comparten responsabilidades para el desarrollo de planes educativos y para la transición a los futuros servicios preescolares.' : 'The staff works cooperatively from their disciplinary roles and shares responsibilities for the development of educational plans and the transition to future preschool services.',
  'Las interacciones entre niño/niña y adulto son saludables, satisfactorias y en un clima amigable (el adulto y/o figura de apego propone interacciones que afianzan el vínculo destacando los aspectos positivos y alentando a la participación).' : 'The interactions between the child and adult are healthy, satisfying, and in a friendly atmosphere (the adult or attachment figure initiates interactions that strengthen the bond, highlighting the positive aspects and encouraging participation).',
  'Cada niño/niña participa en todas las actividades, independientemente de su nivel de desarrollo' : 'Each child participates in all activities, regardless of their level of development.',
  'El niño/niña reconoce y es reconocido por el resto de sus compañeros.' : 'The child recognizes and is recognized by the rest of their peers.',
  'Los niños/as comparten un sistema comunicativo en común, promoviendo interacciones y lazos de amistad entre pares.' : 'The children share a common communication system, promoting interactions and friendship bonds among peers.',
  'Se les propone a los niños y niñas modelos de comunicación apropiados para cada uno/una.' : 'Appropriate communication models are proposed to each child, taking into consideration their individual needs.',
  'Se involucra a las familias en la implementación de los planes y en la organización de la transición de los niños/niñas a los nuevos servicios.' : 'Families are involved in the implementation of the plans and the organization of the transition of the children to new services.',
  'Las familias implementan en sus casas estrategias y orientaciones brindadas por el programa' : 'Families implement strategies and guidance provided by the program in their homes.',

  //Action-plan
  'Esta evaluación no tiene un plan de acción' : 'This evaluation does not have an action plan',
  '¿Cuáles son las necesidades y problemas identificados para atender prioritariamente en el proceso de cambio sobre el que se realiza el plan?' : 'What are the identified needs and problems to be prioritized in the change process on which the plan is based?',
  '¿Cuáles son los centros de interés que motivan y movilizan al equipo de trabajo para participar del proceso de cambio?' : 'What are the centers of interest that motivate and mobilize the work team to participate in the change process?',
  '¿Cuáles son los obstáculos y las dificultades que se pueden encontrar al momento de implementar el plan de acción para resolver los problemas identificados?' : 'What are the obstacles and difficulties that may be encountered when implementing the action plan to address the identified problems?',
  '¿De qué recursos disponemos en el corto, mediano y largo plazo para llevar adelante un plan de cambio?' : 'What resources do we have in the short, medium, and long term to carry out a change plan?',
  '¿Cuáles son los resultados de la evaluación de los indicadores de calidad de Perkins que se deciden priorizar en el plan de acción?' : 'What are the results of the evaluation of Perkins quality indicators that are decided to be prioritized in the action plan?',
  'Meta a cumplir': 'Goal to be achieved',
  'Resultado esperado': 'Expected outcome',
  'Actividades para cumplir meta': 'Activities to achieve the goal',
  'Editando': 'Editing',
  'Estado' : 'Status',
  'Realizado' : 'Done',
  'En progreso' : 'In progress',
  'No realizado' : 'Not done',
  'Completar campo': 'Complete field',
  'No hay información aun cargada': 'There is no information loaded yet',
  'Aún no hay actividades asignadas': 'There is no activities loaded yet',
  'Aún no hay observaciones': 'There is no observations loaded yet',
  'Meta': 'Goal',
  'Observaciones': 'Observations',
  'Fecha': 'Date',
  'Agregar meta' : 'Add goal',
  'Imprimir' : 'Print',
  'Presencial': 'In person',
  'Virtual': 'Virtual',
  'Reuniones de seguimiento.': 'Follow-up meetings.',
  'Comunicaciones por e-mail, WhatsApp.': 'Communications by e-mail, WhatsApp.',
  'Monitoreo sobre la implementación de contenidos recibidos desde la formación de la academia.': "Monitoring the implementation of content received since the academy's formation.",
  'Monitoreo sobre actividades singulares/distintivas, prácticas referidas a casos individuales.': 'Monitoring of singular/distinctive activities, practices referring to individual cases.',
  'Otras formaciones.': 'Other education.',
  'Otras actividades.': 'Other activities.',
  'Actividad': 'Activity',
  'Próximos acuerdos': 'Upcoming agreements',
  'Categoría': 'Category',

  'Las puntuaciones deben sumar 100': 'Scores must add up to 100',
  'Puntuaciones guardadas con éxito': 'Scores saved successfully',
  'Peso teórico': 'Theoretical weight',
  'No aplica': 'Not applicable',
  'No se encuentra evidencia': 'Evidence was not found',
  'Focal': 'Focal',
  'En expansión': 'In expansion',
  'Transversal': 'Cross-sectional',
  'Política institucional': 'Institutional policy',
  'Cultura innovadora': 'Innovative culture',
  'Indicadores de calidad de Perkins': 'Perkins quality indicators',
  'Estos indicadores son el resultado de una extensa discusión entre el personal profesional de Perkins Internacional y respetados colegas de diferentes regiones donde este programa presta servicios.': 'These indicators are the result of extensive discussion between the staff of Perkins International and respected professionals and colleagues from different regions served by the program.',
  'Esta versión de 2021 para Latinoamérica surge de la iniciativa por llevar adelante un proceso de validación y actualización en función de los cambios de paradigma, la experiencia ganada por el equipo de especialistas y de la diversidad de entornos y participantes que están involucrados en las diferentes experiencias educativas con estudiante con sordoceguera, discapacidad sensorial y/o discapacidad múltiple.': 'This version of 2021 for Latin America is the result of the initiative to carry out a validation and updating process based on the paradigm changes, the experience gained by the team of specialists and the diversity of environments and participants who are involved in the different educational experiences with students with deafblindness, sensory impairment and/or multiple disabilities.',
  'La perspectiva de revisión del instrumento es la que entiende a la evaluación como un proceso inherente a la educación que aporta una mirada crítica y reflexiva a la experiencia educativa. El resultado de la evaluación no marca un lugar de certezas absolutas, sino que permite analizar la experiencia educativa en su complejidad, entendida como una intersección de acciones, de implementación de estrategias y proyecciones': 'The revision perspective of the instrument is that which understands evaluation as a process inherent to education that provides a critical and reflective view of the educational experience.The evaluation results do not mark a place of absolute certainty, but allow us to analyze the educational experience in its complexity, understood as an intersection of actions, implementation of strategies, and projections.',
  'Se espera que esta herramienta sea utilizada por los programas para la autorreflexión y autoevaluación, como parte del proceso de desarrollo de sus planes internos de formación y desarrollo del programa.': 'It is expected that this tool is used by the programs for self-reflection and auto evaluation as they develop their internal training plans and the programs themselves.',
  'Asimismo, también puede ser utilizada como una herramienta para los evaluadores externos, para medir la capacidad de un programa, desarrollar un plan para apoyar el futuro y calcular el impacto de este apoyo en la construcción de capacidad.': 'It is also paramount that the tool can be used by external evaluators to establish a program’s potential, design a plan for the future, and estimate impact.',
  'Principios': 'Principles',
  'LOS PRINCIPIOS ORIENTADORES DE ESTA REVISIÓN SON': 'THE GUIDING PRINCIPLES OF THIS REVIEW ARE',
  'Prácticas Promotoras de Autonomía': 'Education Practices that Promote Autonomy',
  'Experiencias educativas que, por su valor innovador y transformador en la implementación de estrategias, disponibilidad de material, accesibilidad universal responden a las necesidades y demandas de los estudiantes para el desarrollo de su autonomía y participación.': 'Learning experiences that address the needs and requests of students developing their autonomy and motivating their participation. These practices are innovative and transformative on strategy implementation, provide material to the students, and are universally accessible.',
  'Trabajo Colaborativo': 'Collaborative Teamwork',
  'Dinámicas de trabajo que convocan a la participación de maestros, autoridades y familia, cuyas metas y estrategias son diseñadas e implementadas conjuntamente para lograr un trabajo articulado.': 'Teachers, staff, administrators, and family members are welcome to participate in the learning process under equal conditions. They define and implement their goals and strategies as a collaborative team.',
  'Comunicación Transversal (principio y método)': 'Communication Transversality',
  'Se considera a la comunicación como una filosofía de trabajo y como una estrategia metodológica.': 'Communication is considered a work philosophy and a methodological strategy.',
  'Como filosofía de trabajo: significa considerarla fundamental para el desarrollo del aprendizaje y las relaciones interpersonales, por lo cual se debe estudiar y planificar en todas las áreas de participación de los estudiantes.': 'As a work philosophy it is a key element of the learning experience and personal relationships. Therefore, every area of participation of the students needs to be studied and planned accordingly.',
  'Como estrategia de trabajo: considerarla a través de decisiones metodológicas en la implementación de dinámicas, diseño de materiales y gestión de apoyos, dando respuesta a las diversidades funcionales de los estudiantes.': 'As a methodological strategy it is taken into account when new dynamics are implemented, materials are designed and support is managed, addressing the students’ different functional needs.',
  'Inclusión Educativa': 'Inclusion',
  'Se trata de una cultura educativa basada en garantizar la igualdad de oportunidades y circunstancias para el aprendizaje y la continuidad de los estudiantes en el entorno escolar. Se toman decisiones en diferentes niveles: práctica educativa, política educativa y cultura educativa, procurando evitar los riesgos de exclusión.': 'Providing equal opportunities to the students and preventing them from dropping out is at the core of the educational culture. The school works to reduce risks of exclusion at the different levels of decision making: practice, policy, and culture.',
  'Objetivos de la implementación de los indicadores de calidad': 'Objectives of the implementation of quality indicators',
  'Identificar': 'Identify',
  'aquellas prácticas educativas que dan como resultado el logro de los objetivos buscados, definiendo el nivel de impacto en relación a las posibilidades que dan para la participación del estudiante con DM/DS, con el fin de poder replicarlos en otras instancias similares.': 'those educational practices that bring us to the desired results, defining the impact according to the opportunities given to the students with disabilities to participate. The purpose is to replicate these practices elsewhere.',
  'Retroalimentar': 'Provide feedback',
  'al usuario del instrumento a través de la información que arroja, permitiéndole la toma de decisiones y el seguimiento según los principios sobre el que se sostiene el mismo.': 'to the person using the tool (internal or external evaluator) about the practices that have been implemented, the decisions made for the improvement of the program and the follow up, in accordance with the principles on which the tool is based.',
  'Indicadores de calidad de Perkins - Características generales': 'Quality indicators for programs Overview',
  'Los indicadores están organizados en siete categorías de acuerdo a los tópicos que se evalúan.': 'Indicators are organized in 7 categories according to the topics to assess.',
  'Categorías de indicadores': 'Indicators Categories',
  'Categoría 1 al 4: estas categorías agrupan indicadores que se focalizan en la escena más general de la escuela. A través de ellos se puede visibilizar participación, decisiones y organización que, a través de los acuerdos articulados, nos acercan a entender aspectos generales de la cultura escolar': 'Categories 1 to 4: these focus on the program’s general picture. They help us visualize the participation, decisions, and organization that allow us to understand the program’s culture more broadly.',
  'Categorías del 5 al 7: estas categorías si bien contribuyen a un análisis general orientan la observación de individualidades situadas en la relación maestro-estudiante-familia de personas con DM/DS.': 'Categories 5 to 7: these provide useful information about the program; however, they focus more on the relationships among teachers, family members, and students with disabilities in a specific context.',
  '1- Conocimiento sobre el estudiante: conocimiento, toma de decisiones e instrumentación de herramientas para recolectar información sobre el estudiante. Esto permite valorarlo y a partir de ello diseñar planes de trabajo.': '1- Knowledge about the student: knowledge, decision-making, strategies and tools implemented in order to collect information about the students in order to assess their needs and design working plans accordingly.',
  '2- Planificación, actividades y contenidos: vinculación de actividades, propuestas, estrategias y contenidos con las demandas y necesidades educativas.': '2- Plans, activities and contents: activities, educational proposals, strategies and content that are intertwined with the educational needs and requirements of the students.',
  '3- Organización del entorno educativo: aspectos sobre la organización del entorno en el que se desarrolla la práctica educativa a partir de la administración de variables tiempo y contexto.': '3- Organization of the educational environment: aspects related to the organization of the environment in which the learning experience is developed, taking into account time and context.',
  '4- Gestión escolar: determinaciones sobre la disposición de los recursos humanos, implementación de estrategias y trabajo en equipo para el desarrollo de los programas educativos de los estudiantes.': '4- Program management: decision-making about staff availability, strategy implementation, and team work in the development of the educational programs.',
  '5- Sobre los profesionales: indicadores sobre el conocimiento específico de los profesionales para dar respuesta a las necesidades de los estudiantes, las dinámicas de trabajo colaborativo implementadas y la participación de la familia.': '5- About the staff: staff’s specific knowledge that allows them to meet students’ needs, collaborative teamwork strategies, and families’ participation.',
  '6- Sobre la participación de los estudiantes: comportamientos, manifestaciones e interacciones comunicativas observados en los estudiantes que son producto de las estrategias, decisiones y planes implementados en la escuela.': '6- About students’ participation: students’ behavior, expressions, and communicative interactions observed as a result of the strategies and lessons adopted by the school.',
  '7- Sobre la familia: participación de la familia en los diferentes niveles y como miembros activos del equipo escolar.': '7- About the family: family’s participation in all the different educational stages; as active and recognized members of the school’s community and the team involved in the student’s education.',
  'Niveles de logro definidos por el resultado alcanzado': 'Levels of achievement according to the results reached',
  'Para cumplimentar con los objetivos se definen los siguientes niveles de impacto': 'In order to meet the goals and assess the progress of the program, in relation with the tool’s principles, the following levels of achievement have been defined',
  'se identifican prácticas, acciones, decisiones o materiales que han sido implementados adecuadamente sólo en algún aspecto o por alguna persona de la comunidad educativa. Se desarrollan en un nivel específico y en tiempos discontinuos.': 'practices, actions, decisions, or materials have been properly incorporated only in one aspect or by one person from the educational community. These are being developed at a specific level and in a discontinuous and non-recurring fashion.',
  'se identifican prácticas, acciones, decisiones o materiales implementados adecuadamente, en forma discontinua pero recurrente, realizadas por varias personas y en diversas áreas o escenarios.': 'practices, actions, decisions, or materials have been properly incorporated, discontinuously but recurrently, by several people in different areas or scenarios.',
  'se identifican prácticas, acciones, decisiones o materiales implementados adecuadamente y en forma más sistemática y periódica. Se apoya en diferentes niveles de gestión e involucra a varios actores, por lo que alcanza a la mayoría de las áreas o escenarios/espacios.': 'practices, actions, decisions, or materials have been properly incorporated in a systematic and regular manner. Different levels of the school management and the community are involved, reaching most areas or scenarios.',
  'se identifican decisiones, acciones, proyectos de intención y prácticas institucionales innovadoras documentadas que involucran a todas las áreas y espacios de la escuela y a todos los miembros de la comunidad educativa.': 'decisions, actions, projects, and innovative institutional practices are documented and involve every school area and all members of the community. All these are part of the program’s identity.',
  'existe evidencia de decisiones, acciones y proyectos orientados a la creación y desarrollo de estrategias innovadoras en forma habitual. Esto define un rasgo identitario particular de liderazgo en relación a otras prácticas, instituciones y al impacto en la comunidad.': 'there is evidence of decisions and projects frequently oriented towards the creation and development of innovative actions. There is leadership in comparison with other practices, schools or organizations and a real impact in the community is observed. Experience and knowledge are shared with others. This innovative culture is not a final destination, but it is rather in permanent change and evaluation in the pursuit of educational excellence.',
  'NOTA: LA VALORACIÓN DE UN INDICADOR EN UN NIVEL SUPONE QUE TODOS LOS NIVELES ANTERIORES HAN SIDO SUPERADOS.': 'NOTE: THE ASSESSMENT OF AN INDICADOR AT A CERTAIN LEVEL IMPLIES THAT ALL PRECEDING LEVELS HAVE BEEN OVERCOME.',
  'Implementación': 'Implementation',
  'La aplicación puede seguir dos estrategias: la observación directa Y/o la entrevista. Cada estrategia se complementa entre sí para poder recoger la información necesaria.': 'Implementation can follow two strategies: direct observation or an interview. Each strategy complements each other so that all information needed can be gathered.',
  'La información obtenida de la observación y/o de la entrevista se introduce en una hoja de cálculo en un proceso similar al seguido con la versión anterior de los PQI (2018). Cada criterio de evaluación se valora por separado según los diferentes niveles de logro. La herramienta calcula una puntuación para cada criterio, indicador y categoría, para posteriormente estimar el progreso general de un programa.': 'The information obtained from the observation and/or from the interview is inserted into a spreadsheet in a similar process to the one followed with the previous version of the PQIs (2018). Every evaluation criteria is assessed separately according to the different levels of achievement. The tool calculates a score for each criteria, indicator, and category, to later estimate the general progress of a program.',
  'Bienvenidos a la Aplicación Evaluadora de Indicadores de Calidad de Perkins': 'Welcome to Perkins Quality Indicator Evaluator Application',
  'Ingresa con tu cuenta': 'Login with your account',
  'Email o usuario': 'Email or user',
  '¿Olvidaste tu contraseña?': 'Forgot your password?',
  'Iniciar sesión': 'Login',
  'Políticas de Privacidad': 'Privacy Policies',
  'Ingresa tu dirección de email y te enviaremos un link de recuperación': 'Enter your email address and we will send you a recovery link',
  'Enviar email': 'Send email',
  'Volver': 'Return',
  '¿Estás seguro de eliminar esta nota': 'Are you sure you want to delete this note',
  '¿Estás seguro de eliminar esta respuesta': 'Are you sure you want to delete this answer',
  '¿Estás seguro de eliminar esta meta': 'Are you sure you want to delete this goal',
  '¿Estás seguro de eliminar esta actividad': 'Are you sure you want to delete this activity',
  '¿Estás seguro de eliminar este resultado': 'Are you sure you want to delete this result',
  '¿Estás seguro de eliminar esta observación' : 'Are you sure you want to delete this comment',
  'Preguntas y respuestas' : 'Questions & answers',
  'Metas y actividades' : 'Goals & activities',

  //user validations
  'Debe tener un mínimo de 8 caracteres': 'Ensure this field has at least 8 characters.',
  'Ya existe un usuario registrado con este nombre.' : 'A user is already registered with this name',
  'Las contraseñas no coinciden':'Passwords do not match',
  'Ha ocurrido un error, intente nuevamente más tarde' : 'An error has occurred. Please try again later.',
  'Ya existe una cuenta registrada con este correo electrónico.' : 'An account with this email address already exists.',
  'There is already a representative for the selected country' : 'There is already a representative for the selected country.',
  'There is already a representative for the selected region' : 'There is already a representative for the selected region.',
  'There is already a representative for the selected school' : 'There is already a representative for the selected school.',
  'Debe asignar al menos un elemento' : 'You must assign at least one item.',
  'You must specify a country' : 'You must specify a country',
  'You must specify a region' : 'You must specify a region',
  'You must specify a school' : 'You must specify a school',
  'Nombre del archivo demasiado largo': 'File name too long',
  'The file extension is not valid': 'The file extension is not valid',
  'The file exceeds the size allowed for its extension': 'The file exceeds the size allowed for its extension',

  //Alerts from backend/ validations
  'You do not have permission to delete this comment.' : 'You do not have permission to delete this comment.',
  'Ensure this field has no more than 1000 characters.': 'Ensure this field has no more than 1000 characters.',
  'Ensure this field has at least 5 characters.' : 'Ensure this field has at least 5 characters.',
  'Demasiados caracteres' : 'Too many characters',
  'Ocurrió un problema, vuelva a intentar más tarde': 'There was a problem, please try again later',
  'This field may not be blank.': 'This field may not be blank.',
  'This password is too short. It must contain at least 8 characters.': 'This password is too short. It must contain at least 8 characters.',
  'Ensure this field has at least 8 characters.': 'Ensure this field has at least 8 characters.',

  //otras
  'Ingrese un mínimo de 3 caracteres' : 'Please enter a minimum of 3 characters',
  '¿Eliminar el archivo:' : 'Delete file:',
  'No hay archivos cargados' : 'No files found',
  'Descarga clickeando en los iconos de los archivos': 'Download by clicking on the file icons',
  'Link de recuperación enviado a la dirección de email ingresada.': 'Recovery link sent to the email address entered.',
  'No existe usuario vinculado con el email ingresado.': 'There is no user linked to the email entered.',
  'Recuperar contraseña': 'Recover password',
  'Token inválido o expirado': 'Invalid or expired token',
  'Por favor intentelo con un nuevo token' : 'Please try with a new token',
  'Ingresa tu nueva contraseña' : 'Enter your new password',
  'Nueva contraseña' : 'New password',
  'Cambiar': 'Change',
  'Imprimir sección': 'Print section',
  '¿Cambiar contraseña?' : 'Change password?',
  'cambiar rol': 'change role',
  'cambiar el rol eliminaría relaciones con paises, regiones y escuelas.' : 'Changing the role would eliminate relationships with countries, regions, and schools.',
  'El archivo que se desea cargar debe tener alguna de las siguientes extensiones y tamaños máximos': 'The file you want to upload must have one of the following extensions and maximum sizes',
  'para': 'for',
  'Escuela no encontrada.': 'School not found.',

  //Modal impresion
  '¿Problemas para imprimir los gráficos': 'Having trouble printing the graphics',
  'Al presionar el botón de imprimir y dirigirse a la interfaz de impresión, puede ver diferencias en el tamaño y disposicion de los gráficos y de la tipografía, según el navegador web que usted utilize (recomendamos utilizar Chrome o Firefox).': 'When you press the print button and go to the print interface, you may see differences in the size and layout of the graphics and typography, depending on the web browser you use (we recommend using Chrome or Firefox).',
  'También puede haber variaciones segun el zoom que haya fijado en su navegador, se recomienda usar el valor por defecto (100%) al momento de presionar el botón de "imprimir gráficos".': 'There may also be variations depending on the zoom you have set in your browser, it is recommended to use the default value (100%) when pressing the “print graphics” button.',
  'En cualquiera de los casos, cuando presione el botón, se abrira el modal de impresión que es un componente que no depende de nuestra aplicación, sino del navegador.': 'In any case, when you press the button, the print modal will open, which is a component that does not depend on our application, but on the browser.',
  'En todos los navegadores encontrará aqui una versión distinta de esta funcionalidad, pero todos poseen algunas funciones útiles con las cuales puede darle mas personalización a la disposicion y tamaño de los elementos.': 'In all browsers you will find here a different version of this functionality, but all have some useful functions with which you can give more customization to the layout and size of the elements.',
  'Por ejemplo, puede ajustar los márgenes de la página, seleccionar si desea imprimir en orientación vertical u horizontal, y decidir si desea imprimir los encabezados y pies de página del navegador.': 'For example, you can adjust the page margins, select whether you want to print in portrait or landscape orientation, and decide whether you want to print the browser’s headers and footers.',
  'Además, algunos navegadores ofrecen la opción de escalar el contenido de la página para que se ajuste al tamaño del papel. Esto puede ser útil si encuentra que el contenido de la página es demasiado grande o demasiado pequeño cuando se imprime.': 'In addition, some browsers offer the option to scale the page content to fit the paper size. This can be useful if you find that the page content is too large or too small when printed.',
  'Ejemplo en Google Chrome': 'Google Chrome example',

  //Reports
  'Reportes': 'Reports',
  'Reporte': 'Report',
  'Time': 'Time',
  'Acuerdos': 'Agreements',
  'Crear reporte': 'Create report',
  '¿Estás seguro de eliminar este reporte': 'Are you sure you want to delete this report',
  'No hay reportes asignados para este país.' : 'There are no reports assigned to this country.',
  'No hay reportes asignados para esta región.' : 'There are no reports assigned to this region.',
}
